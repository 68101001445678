import {
    CLIENT_CLEARED,
    CLIENT_LOADING,
    APPLY_CLIENT_TO_RELATION,
    PRODUCT_FAILED,
    CASETYPES_FAILED,
    CASETYPES_BY_EXPRESSION_LOADED,
    PRODUCT_CLEARED,
    PERSON_INFO_LOADED,
    PRODUCT_SELECTED,
    PRODUCT_DETAIL_LOADING,
    PRODUCT_RELATIONS_LOADING,
    CASETYPES_LOADED,
    CASETYPE_SELECTED,
    CASETYPE_SEARCH_TEXT_CHANGED,
    CASETYPES_LOADING,
    CASETYPE_SET_FILTER,
    CASETYPE_STARTING,
    CASETYPE_STARTED,
    CASETYPE_FREQUENCIES_LOADED,
    CASETYPE_ONLY_FREQUENT_CHANGED,
    STARTER_CASE_STARTED,
    STARTER_SEARCH_SELECTED_EVENT,
    STARTER_SEARCH_DELETED_EVENT,
    CASETYPE_FAILED,
    CASETYPE_CLEAR_FILTER,
    ALERT_DANGER,
    ALERT_WARNING,
    CASETYPES_BY_EXPRESSION_FAILED,
    CASETYPES_BY_EXPRESSION_LOADING,
    CLIENT_LOADED,
    CLIENT_LOADING_FAILED,
    PRODUCT_RELATIONS_LOADING_FAILED,
    CLIENT_PRODUCTS_PAGE_LOADED,
    ACTIVE_PRODUCTS_LOADING,
    ACTIVE_PRODUCTS_LOADING_FAILED,
    HISTORY_PRODUCTS_LOADING,
    HISTORY_PRODUCTS_LOADING_FAILED,
    CARD_PRODUCTS_LOADING_FAILED,
    CARD_PRODUCTS_LOADING,
    PRODUCT_LOADING_TYPE,
    CLEAR_CARDS,
    RESET_PRODUCTS,
    CASETYPE_GTI_LINK_LOADED,
    CASETYPE_GTI_LINK_LOADING,
    REPRESENTATIVES_LOADED,
    REPRESENTATIVES_LOADING,
    SET_REPRESENTATIVE,
    STARTER_SEARCH_COMPLETE_EVENT,
    SET_CLIENT_TYPE,
    CASETYPE_GTI_LINK_LOADING_FAILED,
    CASE_POLLING_IN_PROGRESS,
    CASE_POLLED,
    CASE_POLL_FAILED,
    STARTED_ASYNC_CASE,
    CASE_POLL_TIMEDOUT,
    CASE_POLL_SUCCESFUL,
    SET_COMPANY,
    SET_AUTHORIZED_PERSON, SET_PERSON
} from './consts';
import Resource from './serverresource';
import {getCodebook, getCodebookValue} from './codebook';
import {isPerson} from "./helper/actionHelper";
import {normalizeForSearch, extractParamFromURL} from "./utils";
import {fireEvent} from "../core/actions/eventActions";
import {fireShowAlert} from "./actions/alertActions";
import {refreshAccessToken} from "./actions/authActions";
import {ActorUtils} from "./helper/actorUtils";

export const clientStartLoading = () => (
    {type: CLIENT_LOADING}
)

export const personLoaded = (data) => (
    {type: SET_PERSON, data}
)

export const companyLoaded = (data) => (
    {type: SET_COMPANY, data}
)

export const authorizedPersonLoaded = (data) => (
    {type: SET_AUTHORIZED_PERSON, data}
)

export const representativesLoaded = (data) => (
    {type: REPRESENTATIVES_LOADED, data}
)

export const representativesLoading = () => (
    {type: REPRESENTATIVES_LOADING}
)

export const setRepresentative = (customerId) => (
    {type: SET_REPRESENTATIVE, customerId}
);

export const clientLoadingFailed = () => (
    {type: CLIENT_LOADING_FAILED}
)

export const productSelected = (data) => (
    {type: PRODUCT_SELECTED, data}
)

export const setClientCleared = () => (
    {type: CLIENT_CLEARED}
)

export const setProductCleared = () => (
    {type: PRODUCT_CLEARED}
)

export const personInfoLoaded = (data) => (
    {type: PERSON_INFO_LOADED, data}
)

export const productFailed = () => (
    {type: PRODUCT_FAILED}
)

export const productRelationsLoading = () => (
    {type: PRODUCT_RELATIONS_LOADING}
)

export const productRelationsLoadingFailed = () => (
    {type: PRODUCT_RELATIONS_LOADING_FAILED}
)

export const productDetailStartLoading = () => (
    {type: PRODUCT_DETAIL_LOADING}
)

export const caseTypeStartLoading = () => (
    {type: CASETYPES_LOADING}
)

export const caseTypesFailed = () => (
    {type: CASETYPES_FAILED}
)

export const resetProducts = () => (
    {type: RESET_PRODUCTS}
)

export const clientProductsPageLoaded = (products, productLoadingType,  backendProductDictionary) => (
    {type: CLIENT_PRODUCTS_PAGE_LOADED, products, productLoadingType,backendProductDictionary}
)

export const activeProductsLoading = (loading) => (
    {type: ACTIVE_PRODUCTS_LOADING, loading}
)

export const activeProductsLoadingFailed = (error) => (
    {type: ACTIVE_PRODUCTS_LOADING_FAILED, error}
)

export const historyProductsLoading = (loading) => (
    {type: HISTORY_PRODUCTS_LOADING, loading}
)

export const historyProductsLoadingFailed = (error) => (
    {type: HISTORY_PRODUCTS_LOADING_FAILED, error}
)

export const cardProductsLoading = (loading) => (
    {type: CARD_PRODUCTS_LOADING, loading}
)

export const cardProductsLoadingFailed = (error) => (
    {type: CARD_PRODUCTS_LOADING_FAILED, error}
)

export const clearPaymentCards = () => (
    {type: CLEAR_CARDS}
)

export const caseTypesLoaded = (list) => (
    {type: CASETYPES_LOADED, list}
)

export const caseTypeByExpressionLoaded = (list) => (
    {type: CASETYPES_BY_EXPRESSION_LOADED, list}
)

export const caseTypeByExpressionFailed = () => (
    {type: CASETYPES_BY_EXPRESSION_FAILED}
)

export const caseTypeByExpressionLoading = () => (
    {type: CASETYPES_BY_EXPRESSION_LOADING}
)

export const caseTypeFrequenciesLoaded = (data) => (
    {type: CASETYPE_FREQUENCIES_LOADED, data}
)

export const selectCaseType = (selected) => (
    {type: CASETYPE_SELECTED, selected}
)

export const startingCase = () => (
    {type: CASETYPE_STARTING}
)

export const startedCase = () => (
    {type: CASETYPE_STARTED}
)

export const startFailed = () => (
    {type: CASETYPE_FAILED}
)

export const caseTypeSearchTextChanged = (text) => (
    {type: CASETYPE_SEARCH_TEXT_CHANGED, text}
)

export const caseTypeOnlyFrequentChanged = (value) => (
    {type: CASETYPE_ONLY_FREQUENT_CHANGED, value}
)

export const applyClientToRelation = (personsInRelations) => (
    {type: APPLY_CLIENT_TO_RELATION, personsInRelations}
)

export const setCaseTypesFilter = (selectedTag) => (
    {type: CASETYPE_SET_FILTER, selectedTag}
)

export const clearCaseTypesFilter = (selectedTag) => (
    {type: CASETYPE_CLEAR_FILTER, selectedTag}
)

export const caseTypeGtiLinkLoaded = (gtiLink) => (
    {type: CASETYPE_GTI_LINK_LOADED, gtiLink}
);

export const caseTypeGtiLinkLoading = (caseType) => (
    {type: CASETYPE_GTI_LINK_LOADING, caseType}
);

export const caseTypeGtiLinkLoadingFailed = () => (
    {type: CASETYPE_GTI_LINK_LOADING_FAILED}
);

export const casePollingInProgress = () => (
    {type: CASE_POLLING_IN_PROGRESS}
);

export const casePolled = (response) => (
    {type: CASE_POLLED, response}
);

export const casePollingFailed = () => (
    {type: CASE_POLL_FAILED}
);

export const casePollTimedOut = () => (
    {type: CASE_POLL_TIMEDOUT}
);
export const casePollSuccessful = () => (
    {type: CASE_POLL_SUCCESFUL}
);

export const startedAsyncCase = (response) => (
    {type: STARTED_ASYNC_CASE, response}
);

export const productCleared = () => {
    return (dispatch) => {
        dispatch(setProductCleared());
        dispatch(fireEvent(STARTER_SEARCH_DELETED_EVENT));
        return dispatch(loadByExpression());
    }
};

export const clientCleared = () => {
    return (dispatch) => {
        dispatch(setClientCleared());
        dispatch(fireEvent(STARTER_SEARCH_DELETED_EVENT));
        return dispatch(loadByExpression());
    }
};

export const loadProduct = (entity) => {
    return (dispatch) => {
        if (entity) {
            return dispatch(loadSingleProduct(entity));
        } else {
            dispatch(productFailed())
        }
    }
}

export const selectPersonRelation = (client, loadAsAuthorized) => {
    return (dispatch, getState) => {
        const loadedCluid = getState().client?.data?.cluid;
        if (client.cluid === loadedCluid) {
            // nothing
            return Promise.resolve();
        }
        if(loadAsAuthorized){
            dispatch(authorizedPersonLoaded(client));
        } else {
            isPerson(client) ? dispatch(personLoaded(client)) : dispatch(companyLoaded(client));
        }
        dispatch(fireEvent(STARTER_SEARCH_SELECTED_EVENT));
        return dispatch(loadByExpression());
    }
}

export const loadClient = (cluid, isPerson, isSkipLoadByExpression) => {
    return (dispatch) => {
        if (isPerson) {
            return dispatch(loadPerson(cluid)).then(() => {
                if(!isSkipLoadByExpression) {
                    return dispatch(loadByExpression())
                }else{
                    return Promise.resolve();
                }
            })
        } else {
            return dispatch(loadCompany(cluid)).then(() => {
                if(!isSkipLoadByExpression) {
                    console.log("BBBBBBB!!!!")
                    return dispatch(loadByExpression())
                }else{
                    return Promise.resolve();
                }
            })
        }
    }
}

const selectProduct = (product, skipLoadByExpression) => {
    return (dispatch) => {
        return dispatch(getCodebook("CB_PartyProductInstanceRoleType"))
            .then(roleTypes => {
                product.relations.forEach(r => {
                    r.roleTypeName = getCodebookValue(roleTypes, r.roleType);
                });
                dispatch(productSelected(product));
                dispatch(fireEvent(STARTER_SEARCH_SELECTED_EVENT));
                return dispatch(loadRelations(product.relations));
            })
            .then(() => {
                if(!skipLoadByExpression) {
                    return dispatch(loadByExpression());
                }
            })
    }
}

export const loadRelations = (relations) => {
    return (dispatch, getState) => {
        dispatch(productRelationsLoading());
        const loadedClient = getState().client?.data;

        // clear client when found product does not have match selected client
        const matchedCluid = relations.map(r => r.cluid).find(cluid => loadedClient?.cluid === cluid);
        if (!matchedCluid) {
            dispatch(setClientCleared());
        }

        const filteredRelations = [...new Set(relations.map(r => r.cluid))].map(cluid => relations.find(r1 => cluid === r1.cluid));
        const promises = filteredRelations.map(r => r.partyType === "FO" ?
            dispatch(getPerson(r.cluid, getState().client?.data)) :
            dispatch(getCompany(r.cluid, getState().client?.data)));
        return Promise.all(promises)
            .then(personsInRelations => {
                if (!loadedClient && filteredRelations.length === 1) {
                    dispatch(selectPersonRelation(personsInRelations[0], true));
                }
                return dispatch(applyClientToRelation(personsInRelations))
            })
            .catch(err => {
                dispatch(fireShowAlert("Načtení osob navázaných na produkt selhalo", ALERT_WARNING));
                dispatch(productRelationsLoadingFailed());
            });
    }
}

export const loadSingleProduct = (cgpId, skipLoadByExpression) => {
    return (dispatch) => {
        dispatch(productDetailStartLoading());
        return dispatch(getCodebook("CB_BackendProduct"))
            .then((cbBackendProduct) => {
                return fetch(Resource.getSesApi() + "/product/" + cgpId + "/true", {
                    method: "GET"
                })
                    .then(response => response.json())
                    .then(p => {
                        p.backendProductName = getCodebookValue(cbBackendProduct, p.backendProductId);
                        return dispatch(selectProduct(p, skipLoadByExpression));
                    })
                    .catch(() => {
                        dispatch(productFailed());
                        dispatch(fireShowAlert("Zobrazení produktu selhalo", ALERT_WARNING));
                    })
            }).catch(err => {
                dispatch(fireShowAlert("Zobrazení produktu selhalo", ALERT_WARNING));
            })
    }
};

export const getPerson = (cluid, loadedClient) => {
    return (dispatch) => {
        if (loadedClient && cluid === loadedClient.cluid) {
            return Promise.resolve(loadedClient);
        }

        return fetch(Resource.getSesApi() + "/person/" + cluid, {
            method: "GET"
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(data => {
                return Promise.resolve(data)
            })
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            })
    }
}

const getCompany = (cluid, loadedClient) => {
    return (dispatch) => {
        if (loadedClient && cluid === loadedClient.cluid) {
            return Promise.resolve(loadedClient);
        }

        return fetch(Resource.getSesApi() + "/company/" + cluid, {
            method: "GET"
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(data => {
                return Promise.resolve(data)
            })
            .catch(err => {
                console.error(err);
                dispatch(fireShowAlert("Načtení společnosti selhalo", ALERT_WARNING));
                return Promise.reject(err);
            })
    }
};

const getRepresentatives = (cluid) => {
    return (dispatch, getState) => {
        dispatch(representativesLoading())
        return fetch(`${process.env.REACT_APP_WEBAPI_URL_V2}/customers/detail?companyRepresentatives=true`, {
            method: "GET",
            headers: {
                "cluid": cluid,
                "customerId": cluid,
                "username": 'CEN55968',
            }
        })
        .then(Resource.checkStatus)
        .then(response => response.json())
        .then(data => {
            dispatch(representativesLoaded(data));
            return Promise.resolve(data)
        })
        .catch(err => {
            console.error(err);
            dispatch(fireShowAlert("Načtení jednatelů společnosti selhalo", ALERT_WARNING));
            return Promise.reject(err);
        })
    }
}

const loadPerson = (cluid) => {
    return (dispatch) => {
        return dispatch(getPerson(cluid))
            .then(data => {
                dispatch(personLoaded(data));
                dispatch(fireEvent(STARTER_SEARCH_SELECTED_EVENT));
                return Promise.resolve(data);
            })
            .catch(() => {
                dispatch(fireShowAlert("Načtení osoby selhalo", ALERT_WARNING));
                dispatch(clientLoadingFailed());
                return Promise.reject();
            })
    }
};

const loadCompany = (cluid) => {
    return (dispatch) => {
        return dispatch(getCompany(cluid))
            .then(data => {
                return dispatch(getRepresentatives(cluid))
                    .then(() => {
                        dispatch(companyLoaded(data))
                        dispatch(fireEvent(STARTER_SEARCH_SELECTED_EVENT));
                        return Promise.resolve(data);
                    })
                })
            .catch(() => {
                dispatch(clientLoadingFailed());
                return Promise.reject();
            })
    }
}

export const loadProducts = (cluid, isCompany) => {
    return (dispatch) => {
        dispatch(resetProducts());
        dispatch(loadClientActiveProducts(cluid, 0));
        dispatch(loadClientHistoryProducts(cluid, 0));
        if(isCompany){
            dispatch(loadCompanyPaymentCards(cluid, 0));
        }
        else {
            dispatch(clearPaymentCards());
        }
    }
};

export const loadClientActiveProducts = (cluid, page) => {
    return (dispatch) => {
        dispatch(activeProductsLoading(true));
        return dispatch(getCodebook("CB_BackendProduct"))
            .then(cbBackendProduct => {
                return fetch(Resource.getSesApi() + "/product/" + cluid + "/active?page=" + page + "&page-size=50", {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'GET'
                })
                    .then(Resource.checkStatus)
                    .then(response => response.json())
                    .then(response => {
                        dispatch(clientProductsPageLoaded(response.content, PRODUCT_LOADING_TYPE.ACTIVE, cbBackendProduct));
                        if (!response.lastPage) {
                            return dispatch(loadClientActiveProducts(cluid, page + 1,));
                        }
                        return dispatch(activeProductsLoading(false));
                    })
                    .catch(err => {
                        console.error(err);
                        dispatch(activeProductsLoadingFailed(err));
                        return Promise.reject(err);
                    })
            })
    }
};


export const loadClientHistoryProducts = (cluid, page) => {
    return (dispatch) => {
        dispatch(historyProductsLoading(true));
        return dispatch(getCodebook("CB_BackendProduct"))
            .then(cbBackendProduct => {
                return fetch(Resource.getSesApi() + "/product/" + cluid + "/history?page=" + page + "&page-size=50", {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'GET'
                })
                    .then(Resource.checkStatus)
                    .then(response => response.json())
                    .then(response => {
                        dispatch(clientProductsPageLoaded(response.content, PRODUCT_LOADING_TYPE.HISTORY, cbBackendProduct));
                        if (!response.lastPage) {
                            return dispatch(loadClientHistoryProducts(cluid, page + 1));
                        }
                        return dispatch(historyProductsLoading(false));
                    })
                    .catch(err => {
                        console.error(err);
                        dispatch(historyProductsLoadingFailed(err));
                        return Promise.reject(err);
                    })
            })
    }
};

export const loadCompanyPaymentCards = (cluid, page) => {
    return (dispatch) => {
        dispatch(cardProductsLoading(true));
        return dispatch(getCodebook("CB_BackendProduct"))
            .then(cbBackendProduct => {
                return fetch(Resource.getSesApi() + "/product/" + cluid + "/cards?page=" + page + "&page-size=50", {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'GET'
                })
                    .then(Resource.checkStatus)
                    .then(response => response.json())
                    .then(response => {
                        dispatch(clientProductsPageLoaded(response.content, PRODUCT_LOADING_TYPE.CARD, cbBackendProduct));
                        if (!response.lastPage) {
                            return dispatch(loadCompanyPaymentCards(cluid, page + 1));
                        }
                        return dispatch(cardProductsLoading(false));
                    })
                    .catch(err => {
                        console.error(err);
                        dispatch(cardProductsLoadingFailed(err));
                        return Promise.reject(err);
                    })
            });
    };
};


export const loadPersonInfo = (cluid) => {
    return (dispatch) => {
        var body = {
            cluid,
            searchAddress: "VIEW",
            searchAlerts: "VIEW",
            searchEmails: "VIEW",
            searchPhones: "VIEW",
            searchAgreements: "VIEW",
            amlProfile: "NONE",
            findPrivateBanker: "VIEW",
            withChannels: true
        }

        dispatch(clientStartLoading());

        return fetch(Resource.getSesApi() + "/person/personInfo", {
            headers: {
                'Content-Type': 'application/json'
            }, method: "POST", body: JSON.stringify(body)
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(data => {
                dispatch(personInfoLoaded(data));
                return Promise.resolve(data);
            })
            .catch(err => {
                dispatch(fireShowAlert("Načtení detailu osoby selhalo", ALERT_WARNING));
                return Promise.reject(err);
            });
    }
}

export const loadCompanyInfo = (cluid) => {
    return (dispatch) => {
        var body = {
            cluid,
            searchAddress: "VIEW",
            searchAlerts: "VIEW",
            searchEmails: "VIEW",
            searchPhones: "VIEW",
            searchAgreements: "VIEW",
            amlProfile: "NONE",
            findPrivateBanker: "VIEW",
            withChannels: true
        }

        dispatch(clientStartLoading());

        return fetch(Resource.getSesApi() + "/company/companyInfo", {
            headers: {
                'Content-Type': 'application/json'
            }, method: "POST", body: JSON.stringify(body)
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(data => {
                dispatch(personInfoLoaded(data))
                return Promise.resolve(data)
            })
            .catch(err => {
                dispatch(fireShowAlert("Načtení detailu společnosti selhalo", ALERT_WARNING));
                return Promise.reject(err);
            });
    }
}

export const loadByExpression = () => {
    return (dispatch, getState) => {
        const person = getState().client?.data;
        const product = getState().product?.data;
        const representatives = getState().client?.selectedRepresentatives;
        dispatch(caseTypeByExpressionLoading());

        let body = {product, person: isPerson(person) ? person : undefined, company : isPerson(person) ? undefined : person, representatives : representatives ? representatives : undefined};

        return fetch(Resource.getSesApi() + "/categorization/expression", {
            headers: {
                'Content-Type': 'application/json'
            }, method: "POST", body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(list => dispatch(caseTypeByExpressionLoaded(list)))
            .catch(error => {
                //error handled by blocking dialog in App.js
                console.log(error);
                dispatch(caseTypeByExpressionFailed())
            });
    }
};

export const startCase = () => {
    return (dispatch, getState) => {
        const selected = getState().casetype?.selected;
        const client = getState().client?.data;
        const fromTablet = getState().user?.fromTablet;
        const tabletUserConfirmed = getState().user?.fromTabletConfirmed;
        const gti = (getState().caseType?.startBti || getState().user.preferGti);
        
        const request = {
            caseType: selected?.caseType,
            product: getState().product.data,
            person: client && isPerson(client) ? client : null,
            company: client && isPerson(client) ? null : client,
            gti: gti,
            tablet: fromTablet
        };

        dispatch(startingCase());
        let fetchResource = enhanceWithReturnUrl(Resource.getSesApi() + "/case", getState);
        return fetch(fetchResource, {
            headers: {
                'Content-type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(request)
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(urlResponse => handleGtiCaseLink(fromTablet, urlResponse, dispatch, gti))
            .then(urlResponse => {
                if(urlResponse.redirectUri && !urlResponse.sync) {
                    dispatch(startedCase());
                    const targetUrl = urlResponse.redirectUri;
                    dispatch(fireEvent(STARTER_CASE_STARTED, null, urlResponse.cidla));
                    window.open(targetUrl, fromTablet ? '_self' : '_blank');
                }else if(urlResponse.sync){
                    dispatch(startedAsyncCase(urlResponse))
                }
            })
            .catch(error => {
                console.error(error);
                dispatch(fireShowAlert("Založení požadavku selhalo", ALERT_DANGER));
                dispatch(startFailed());
            });
    }
};

export const pollForSyncCase = (cidla) => {
    return (dispatch, getState) => {
        const fetchResource = enhanceWithReturnUrl(Resource.getSesApi() + "/case/poll/" + cidla, getState);
        dispatch(casePollingInProgress());
        return fetch(fetchResource , {headers: {'Accept': 'application/json'}, method: "GET"})
            .then(Resource.checkStatus)
            .then(Resource.getJsonBody)
            .then(pollResponse => {
                console.log(pollResponse)
                dispatch(casePolled(pollResponse))
            })
            .catch(error => {
                console.error(error);
                dispatch(fireShowAlert("Založení požadavku selhalo", ALERT_DANGER));
                dispatch(casePollingFailed())
            })
    }
}

export const loadCaseTypes = (skipLoadByExpression) => {
    return (dispatch) => {
        dispatch(caseTypeStartLoading());

        return Promise.all([
            fetch(Resource.getSesApi() + "/categorization/all", { headers: { 'Accept': 'application/json' }, method: "GET" })
                .then(response => response.json())
                .catch((error) => {
                    //error handled by blocking dialog in App.js
                    dispatch(caseTypesFailed());
                    return Promise.reject(error);
                }),
            fetch(Resource.getSesApi() + "/visibility/expression", {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST", body: JSON.stringify({})
            })
                .then(response => response.json())
                .catch(error => {
                    dispatch(caseTypesFailed());
                    return Promise.reject(error);
                }),
        ]).then(results => {
            const allCases = results[0];
            const visibleCaseTypes = results[1].map(item => item.caseType);

            const list = allCases.map(item => ({
                ...item,
                filter: createItemFilter(item),
                visibleByExpression: visibleCaseTypes.indexOf(item.caseType) !== -1
            }));

            dispatch(caseTypesLoaded(list));
            if (skipLoadByExpression) {
                return dispatch(findMostFrequentCases(list));
            } else {
                dispatch(findMostFrequentCases(list));
                return dispatch(loadByExpression());
            }
        });
    }
};

export const findMostFrequentCases = (caseTypes) => {
    return (dispatch) => {
        let caseTypesCTS = caseTypes.map(ct => ct.caseType);
        return fetch(Resource.getSesApi() + "/case/frequency/8", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, method: "POST", body: JSON.stringify(caseTypesCTS)
        })
            .then(Resource.checkStatus)
            .then(response => response.json())
            .then(data => {
                dispatch(caseTypeFrequenciesLoaded(data))
            }).catch(err => {
                console.error(err);
                dispatch(fireShowAlert("Nejčastěji zakládané požadavky se nepodařilo nalézt", ALERT_WARNING))
            });
    }
};

export const openGtiLink = (gtiLink) => {
    return (dispatch, getState) => {
        const fromTablet = getState().user.fromTablet;
        const tabletUserConfirmed = getState().user?.fromTabletConfirmed;

        let returnUrl = getReturnUrlBase64(fromTablet, tabletUserConfirmed);
        returnUrl = (returnUrl ? "&returnUrl=" + returnUrl : "");

        handleGtiCaseLink(fromTablet, {redirectUri: gtiLink + returnUrl}, dispatch, true)
            .then((tokenEnhancedLink) => {
                window.open(tokenEnhancedLink.redirectUri, fromTablet ? '_self' : '_blank');
            });
    }
};

export const getReturnUrlBase64 = (fromTablet, tabletUserConfirmed) => {

    let returnUrl = extractParamFromURL('returnUrl');
    if (!returnUrl && fromTablet) {
        let url = new URL(window.location.href);
        if (tabletUserConfirmed && !url.searchParams.has("tabletUserConfirmed")) {
            url.searchParams.append("tabletUserConfirmed", "1");
        }
        returnUrl = url.toString();
    }
    return !!returnUrl ? window.btoa(returnUrl) : null;
}

export const handleOboContext = (cluid) => {
    return () => handleOboContextInternal(cluid);
}

export const handleOboContextInternal = (cluid) => {
    return fetch(Resource.getSesApi() + "/obo/context", {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "PUT",
        body: JSON.stringify(cluid)
    });
}

export const deleteOboContext = (username) => {
    return () => deleteOboContextInternal(username);
}


export const deleteOboContextInternal = (username) => {
    return fetch(Resource.getSesApi() + "/obo/context", {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "DELETE",
        body: JSON.stringify(username)
    });
}

//TODO REWORK!!!
const handleGtiCaseLink = (tablet, response, dispatch, gti) => {
    if(gti){
        return new Promise((resolve) => {
            if(!tablet) {
                dispatch(refreshAccessToken({refresh_token: window.sessionStorage.getItem(process.env.REACT_APP_ENV + "-refresh_token")}, "urn:csas:apps:ses", "openid"))
                    .then(data => {
                        response.redirectUri += "#access_token=" + data.access_token;
                        resolve(response);
                    })
            } else {
                response.redirectUri += "#access_token=" + window.sessionStorage.getItem(process.env.REACT_APP_ENV + "-access_token");
                resolve(response);
            }
        });
    }
    return Promise.resolve(response);
}

//tablet vezmeme ze statu ne?
export const getGtiLink = (tablet, caseType, tags, cgpHashId, cgpId, cluid) => {
    return (dispatch, getState) => {

        const productDto = getState().product.data;
        let client = getState().client?.data;
        const authorizedPerson = getState().client?.authorizedPerson;
        const clientType = getState().client?.clientType;

        const representativeCluid = getState().client?.selectedRepresentatives ? getState().client.selectedRepresentatives[0] : null;
        const orgUnitId = getState().user?.identity?.orgUnitId;


        if(!client && cluid) {
            client = {
                cluid: cluid
            }
        }

        let request = {
            caseType: caseType,
            client,
            productDto: productDto,
            orgUnitId: orgUnitId,
            cgpHashId: cgpHashId,
            cgpId: cgpId,
            tags: tags,
            startedFrom: tablet
        }

        const actor = ActorUtils.createActor({
            client,
            clientType,
            authorizedPerson,
            representativeCluid
        })

        dispatch(caseTypeGtiLinkLoading(caseType));
        return fetch(Resource.getSesApi() + "/gti/link", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Smart-Actor': JSON.stringify(actor)
            },
            method: "POST",
            body: JSON.stringify(request)
        }).then(Resource.checkStatus)
            .then(response => response.json())
            .then(response => {
                dispatch(caseTypeGtiLinkLoaded(response));
                return Promise.resolve(response);
            })
            .catch(err => {
                console.warn(err);
                return Promise.reject(null);
            })
    }
};

const createItemFilter = (item) => {
    let filter = [];
    filter.push(normalizeForSearch(item.caseType));
    filter.push(normalizeForSearch(item.description));
    filter.push(normalizeForSearch(item.label));
    filter.push(item.tags.map(t => normalizeForSearch(t)).join(" "));
    return filter;
};

export const scrollOnTop = () => {
    document.getElementById('caseTypesSection').scrollTop = 0;
};

export const setRepresentativeId = (customerId) => {
    return (dispatch) => {
        dispatch(setRepresentative(customerId));
        dispatch(loadByExpression())
        return dispatch(fireEvent(STARTER_SEARCH_COMPLETE_EVENT));
    }
}

const enhanceWithReturnUrl = (baseUrl, getState) => {
    const fromTablet = getState().user?.fromTablet;
    const tabletUserConfirmed = getState().user?.fromTabletConfirmed;

    let returnUrl = getReturnUrlBase64(fromTablet, tabletUserConfirmed);
    returnUrl = (returnUrl ? "?returnUrl=" + returnUrl : "");

    return baseUrl + returnUrl;
}


